<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="contact-support" slot="component">
      <div class="contact-support-form">
        <p class="contact-support-form-title">Contact Support</p>
        <form @submit.prevent="sendToSupport">
          <div class="form-group">
            <div class="error-container" v-if="formError">
              <p class="error">{{ formError }}</p>
            </div>
            <div class="form-control">
              <label for="email">Enter Email ID</label>
              <input type="text" v-model="email" />
            </div>
            <div class="form-control">
              <label for="comment">Comment</label>
              <br />
              <textarea v-model="comment" maxlength="256" />
              <label class="charVal">256 Characters</label>
            </div>
          </div>
          <div class="contact-support-form-button">
            <button class="button-primary btn" type="submit">SENT TO SUPPORT</button>
          </div>
        </form>
      </div>
    </div>
  </Popup>
</template>

<script>
export default {
  props: ["closePopup"],
  data() {
    return {
      width: "35%",
      margin: "10% auto",
      radiusType: "full",
      email: null,
      comment: null,
      formError: null
    };
  },
  watch: {
    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 2000);
    }
  },
  methods: {
    sendToSupport() {}
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue")
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.contact-support {
  padding: 3.4rem;
  &-form {
    &-title {
      font-family: $font-regular;
      color: $clr-light-gd1;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.8rem;
      margin-bottom: 1.2rem;
    }
    &-button {
      margin-top: 3.5em;
      .btn {
        width: 100%;
        padding: 0.6rem;
      }
    }
    .charVal {
      font-size: 10px;
      float: right;
    }
  }
}
</style>
